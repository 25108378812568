import React from 'react';
import { MenuItem, Select } from '@mui/material';

const RodiklioSelect = ({
  rodiklis,
  rodikliuList,
  changeRodiklis,
  fullWidth,
}) => {
  const handleChangeInput = (value) => {
    let result = rodikliuList.find((r) => r.parametras === value);
    changeRodiklis(result ? result : rodikliuList[0]);
  };

  return (
    <Select
      id='rodiklis'
      name='rodiklis'
      value={rodiklis.parametras}
      size='small'
      onChange={(e) => handleChangeInput(e.target.value)}
      fullWidth={fullWidth}
    >
      {rodikliuList.map((r) => (
        <MenuItem key={r.parametras} value={r.parametras}>
          {r.pavadinimas}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RodiklioSelect;
