import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1e56c2',
    },
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 500,
    fontWeightBold: 600,
    backgroundColor: '#1e56c2',
    h6: {
      fontSize: 20,
      fontWeight: 600,
    }
  },
  components: {
    MuiSelect: {
      styleOverrides:{
        root: {
          backgroundColor: "white",
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #c2c1c1de',
          borderRadius: '6px',
          backgroundColor: 'white',
        }
      }
    }
  }
});