export const Spalvos = [
  '#2f4f4f',
  '#228b22',
  '#800000',
  '#00008b',
  '#ff8c00',
  '#2a5df7',
  '#00ff00',
  '#00ffff',
  '#ff00ff',
  '#eee8aa',
  '#6495ed',
  '#ff69b4'
];

export const Primary = '#1e56c2';