export const LaktacijuAmziai = [
  { id: 1, label: '1', min: 1, max: 1 },
  { id: 2, label: '2', min: 2, max: 2 },
  { id: 3, label: '3', min: 3, max: 3 },
  { id: 4, label: '4-5', min: 4, max: 5 },
  //Gal 30 laktaciju kiek daugokai, bet turetu apsaugoti nuo visokiu
  //nesamoningu variantu
  { id: 5, label: '5+', min: 6, max: 30 },
];

export const SomatiniuGrupes = [
  {id: 1, label: '0-200', min: 0, max: 200},
  {id: 2, label: '201-400', min: 201, max: 400},
  {id: 3, label: '401-1000', min: 401, max: 1000},
  {id: 4, label: '1000+', min: 1000, max: 20000},
];

export const RBGrupes = [
  {id: 1, label: '<1', min: 0, max: 0.99},
  {id: 2, label: '1-1.4', min: 1, max: 1.4},
  {id: 3, label: '>1.4', min: 1.41, max: 10},
];

export const UrejosGrupes = [
  {id: 1, label: '<15', min: 0, max: 14},
  {id: 2, label: '15-30', min: 15, max: 30},
  {id: 3, label: '>30', min: 31, max: 100},
];


export const LaktozesGrupes = [
  {id: 1, label: '<3.0', min: 0, max: 2.99},
  {id: 2, label: '3.0-4.3', min: 3, max: 4.3},
  {id: 3, label: '>4.3', min: 4.31, max: 10},
];

export const LaktacijuTrukmes = [
  { id: 1, label: '0-60', min: 0, max: 60 },
  { id: 2, label: '61-120', min: 61, max: 120 },
  { id: 3, label: '121-200', min: 121, max: 200 },
  { id: 4, label: '201-305', min: 201, max: 305 },
  { id: 5, label: '>305', min: 306, max: 999 },
];