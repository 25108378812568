import React from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../context/AuthProvider';

const Header = ({ toggleDrawer }) => {
  const auth = useAuth();

  return (
    <AppBar position='absolute' sx={{ borderRadius: '0px;' }}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          sx={{ marginRight: '30px' }}
          onClick={() => toggleDrawer(true)}
        >
          <MenuIcon />
          {/* <img
            src={logo}
            alt='logo'
            style={{ height: '40px', width: '51px', marginRight: '20px' }}
          /> */}
        </IconButton>
        <Typography
          alignContent='flex-start'
          variant='h6'
          noWrap
          textAlign='left'
          sx={{ flexGrow: 1 }}
        >
          Karvutė
        </Typography>
        <Typography>{auth.user.name}</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
