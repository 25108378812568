import React, { useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

const VeisliuSelect = ({ veisliuList, veisles, changeVeisles, fullWidth }) => {
  const [zymVisos, setZymVisos] = useState(true);

  const handleChange = (checked, value) => {
    if (checked) {
      changeVeisles([...veisles, value]);
      setZymVisos(veisles.length + 1 === veisliuList.length);
    } else {
      changeVeisles(veisles.filter((v) => v !== value));
      setZymVisos(false);
    }
  };

  const handleZymVisas = (checked) => {
    if (checked) {
      setZymVisos(true);
      changeVeisles(veisliuList);
    } else {
      setZymVisos(false);
      changeVeisles([]);
    }
  };

  return (
    <>
      <Typography>Veislės</Typography>
      <Select
        id='veisles'
        name='veisles'
        value={veisles}
        multiple
        displayEmpty
        size='small'
        renderValue={() =>
          zymVisos || veisles.length === 0
            ? 'Visos'
            : 'Veislių: ' + veisles.length
        }
        sx={{ maxWidth: !fullWidth ? '120px' : 'none' }}
        fullWidth={fullWidth}
      >
        <MenuItem key={0} value=''>
          <Checkbox
            checked={zymVisos}
            value=''
            onChange={(e) => handleZymVisas(e.target.checked)}
          />
          <ListItemText primary='Visos' />
        </MenuItem>
        {veisliuList.map((v) => (
          <MenuItem key={v} value={v}>
            <Checkbox
              value={v}
              checked={veisles.indexOf(v) > -1 || zymVisos}
              onChange={(e) => handleChange(e.target.checked, e.target.value)}
            />
            <ListItemText primary={v} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default VeisliuSelect;
