import React from 'react';
import { Box } from '@mui/material';
import {
  LaktacijuAmziai,
  LaktozesGrupes,
  RBGrupes,
  SomatiniuGrupes,
  UrejosGrupes,
} from '../../constants/ParametruGrupes';
import styled from '@emotion/styled';
import GrupiuSelectItem from './GrupiuSelectItem';
import VeisliuSelect from '../home/VeisliuSelect';

export const FlexColumnBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0px;
`;

const GrupiuSelectList = ({
  veisliuList,
  veisles,
  changeVeisles,
  laktacijos,
  changeLaktacijas,
  slsGrupes,
  changeSlsGrupes,
  rbGrupes,
  changeRbGrupes,
  urejosGrupes,
  changeUrejosGrupes,
  laktozesGrupes,
  changeLaktozesGrupes,
}) => {
  const pasirinkimai = [
    {
      pavadinimas: 'Amžius laktacijomis',
      grupes: LaktacijuAmziai,
      duomenys: laktacijos,
      keisti: changeLaktacijas,
    },
    {
      pavadinimas: 'SLS',
      grupes: SomatiniuGrupes,
      duomenys: slsGrupes,
      keisti: changeSlsGrupes,
    },
    {
      pavadinimas: 'R/B',
      grupes: RBGrupes,
      duomenys: rbGrupes,
      keisti: changeRbGrupes,
    },
    {
      pavadinimas: 'Urėja',
      grupes: UrejosGrupes,
      duomenys: urejosGrupes,
      keisti: changeUrejosGrupes,
    },
    {
      pavadinimas: 'Laktozė',
      grupes: LaktozesGrupes,
      duomenys: laktozesGrupes,
      keisti: changeLaktozesGrupes,
    },
  ];

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      gap='10px'
      mt={8}
      marginLeft={1}
    >
      <FlexColumnBox>
        <VeisliuSelect
          veisliuList={veisliuList}
          veisles={veisles}
          changeVeisles={changeVeisles}
        />
      </FlexColumnBox>
      {pasirinkimai.map((grupe) => (
        <FlexColumnBox key={grupe.pavadinimas}>
          <GrupiuSelectItem
            pavadinimas={grupe.pavadinimas}
            grupes={grupe.grupes}
            pasirinktos={grupe.duomenys}
            changeGrupes={grupe.keisti}
          />
        </FlexColumnBox>
      ))}
    </Box>
  );
};

export default GrupiuSelectList;
