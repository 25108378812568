import React, { useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

const GrupiuSelectItem = ({
  pavadinimas,
  grupes,
  pasirinktos,
  changeGrupes,
  fullWidth,
}) => {
  const [zymVisos, setZymVisos] = useState(true);

  const handleChange = (checked, value) => {
    if (checked) {
      changeGrupes([...pasirinktos, Number(value)]);
      setZymVisos(pasirinktos.length + 1 === grupes.length);
    } else {
      changeGrupes(pasirinktos.filter((f) => f !== Number(value)));
      setZymVisos(false);
    }
  };

  const handleZymVisas = (checked) => {
    if (checked) {
      setZymVisos(true);
      changeGrupes(grupes.map((g) => Number(g.id)));
    } else {
      setZymVisos(false);
      changeGrupes([]);
    }
  };

  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Select
        id={pavadinimas}
        name={pavadinimas}
        value={pasirinktos}
        multiple
        displayEmpty
        renderValue={() =>
          zymVisos || pasirinktos.length === 0
            ? 'Visos'
            : `Grupių: ${pasirinktos.length}`
        }
        size='small'
        sx={{ maxWidth: !fullWidth ? '120px' : 'none' }}
        fullWidth={fullWidth}
      >
        <MenuItem key={0} value={0}>
          <Checkbox
            checked={zymVisos}
            value={0}
            onChange={(e) => handleZymVisas(e.target.checked)}
          />
          <ListItemText primary='Visos' />
        </MenuItem>
        {grupes.map((grupe) => (
          <MenuItem key={grupe.id} value={grupe.id}>
            <Checkbox
              checked={pasirinktos.indexOf(grupe.id) > -1 || zymVisos}
              value={grupe.id}
              onChange={(e) => handleChange(e.target.checked, e.target.value)}
            />
            <ListItemText primary={grupe.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default GrupiuSelectItem;
