import { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [rajonas, setRajonas] = useState(
    JSON.parse(localStorage.getItem('rajonas')) || 0
  );
  const [savininkas, setSavininkas] = useState(
    JSON.parse(localStorage.getItem('savininkas')) || ''
  );

  const changeRajonas = (value) => {
    setRajonas(value);
    localStorage.setItem('rajonas', JSON.stringify(value));
  };

  const changeSavininkas = (value) => {
    setSavininkas(value);
    localStorage.setItem('savininkas', JSON.stringify(value));
  };

  return (
    <MenuContext.Provider
      value={{ rajonas, savininkas, changeRajonas, changeSavininkas }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;

export const useMenu = () => {
  return useContext(MenuContext);
};
