import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Primary } from '../../constants/Spalvos';

const Colors = [Primary, '#8e8989'];

const SlsKreiviuGrafikas = ({ isLoading, pavadinimas, data, keys }) => {
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      {isLoading ? (
        <Box
          width='100%'
          height='100%'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart data={data}>
            <CartesianGrid />
            <XAxis dataKey='menuo' />
            <YAxis type='number' allowDataOverflow />
            <Tooltip />
            <ReferenceLine y={200} stroke='orange' strokeDasharray='3 3'>
              <Label
                stroke='orange'
                strokeOpacity={0.6}
                position='insideBottomRight'
                value={200}
              />
            </ReferenceLine>
            <ReferenceLine y={400} stroke='red' strokeDasharray='3 3'>
              <Label
                stroke='red'
                strokeOpacity={0.6}
                position='insideBottomRight'
                value={400}
              />
            </ReferenceLine>
            {keys.map((k, index) => (
              <Line
                key={`line-${k}`}
                dataKey={k}
                type='linear'
                dot={false}
                stroke={Colors[index]}
                strokeWidth={3}
                connectNulls
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default SlsKreiviuGrafikas;
