import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';

const columns = [
  { width: 200, label: 'Karvės Nr.', dataKey: 'karNr' },
  { width: 50, label: 'Grupė', dataKey: 'grPavad' },
  { width: 200, label: 'Veislė', dataKey: 'veisle' },
  { width: 50, label: 'Lakt. amžius', dataKey: 'laktAmzius' },
  { width: 50, label: 'Lakt. trukmė', dataKey: 'laktDiena' },
  { width: 50, label: 'P (kg)', dataKey: 'primilzis' },
  { width: 50, label: 'B (%)', dataKey: 'baltymai' },
  { width: 50, label: 'R/B', dataKey: 'rb' },
  { width: 50, label: 'SLS', dataKey: 'sls' },
  { width: 50, label: 'U(mg%)', dataKey: 'urea' },
];

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant='head'
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{ backgroundColor: 'background.paper' }}
          size='small'
        >
          {column.label}
        </TableCell>
      ))}
      <TableCell
        variant='head'
        style={{ width: 100 }}
        sx={{ backgroundColor: 'background.paper' }}
        size='small'
      ></TableCell>
    </TableRow>
  );
};

const rowContent = (_index, row) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell key={column.dataKey} size='small'>
          {row[column.dataKey]}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const MitybosKarviuLentele = ({ duomenys, rodiklis }) => {
  if (rodiklis === 'urea') {
    columns[1].dataKey = 'grPavadUr';
  } else {
    columns[1].dataKey = 'grPavad';
  }

  return (
    <Box sx={{ height: 300, width: '100%' }}>
      <TableVirtuoso
        data={duomenys}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        style={{ scrollbarWidth: 'thin' }}
      />
    </Box>
  );
};

export default MitybosKarviuLentele;
