import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';

const LimitedMultipleSelect = ({
  pavadinimas,
  grupes,
  pasirinktos,
  maxRiba,
  changeGrupes,
  visos,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const array = typeof value === 'string' ? value.split(',') : value;

    //Ismesim is masyvo pirma pasirinkima, jei perlipa pasirinkimu riba
    if (array.length > maxRiba) {
      array.shift();
    }

    changeGrupes(array);
  };

  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Select
        id={pavadinimas}
        name={pavadinimas}
        value={pasirinktos}
        multiple
        displayEmpty
        renderValue={() =>
          pasirinktos.length === 0
            ? 'Nepasirinkta'
            : `Pasirinkta: ${pasirinktos.length}`
        }
        size='small'
        onChange={handleChange}
        fullWidth
      >
        {maxRiba && (
          <MenuItem disabled value=''>
            <em>Pažymėkite iki {maxRiba} pasirinkimų</em>
          </MenuItem>
        )}
        {visos && (
          <MenuItem key={0} value={0}>
            <Checkbox checked={pasirinktos.indexOf(0) > -1} value={0} />
            <ListItemText primary='visos' />
          </MenuItem>
        )}
        {grupes.map((g) => (
          <MenuItem key={g.id} value={g.id}>
            <Checkbox checked={pasirinktos.indexOf(g.id) > -1} value={g.id} />
            <ListItemText primary={g.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default LimitedMultipleSelect;
