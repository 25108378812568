import { MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

const SingleSelect = ({
  pavadinimas,
  pasirinkimai,
  reiksme,
  changeReiksme,
}) => {
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Select
        id={pavadinimas}
        name={pavadinimas}
        value={reiksme}
        size='small'
        onChange={(e) => changeReiksme(e.target.value)}
        sx={{ minWidth: '100px' }}
        fullWidth
      >
        {pasirinkimai.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SingleSelect;
