import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from './LaktacijuGrafikas';
import { Spalvos } from '../../constants/Spalvos';

const UkioLaktacijuGrafikas = ({
  isLoading,
  rodiklis,
  duomenys,
  laktacijos,
  pikas,
  laktacijuList,
  karve,
  lineAnimation,
}) => {
  const pasirinktosLaktacijos = laktacijuList.filter(
    (l) => laktacijos.indexOf(l.id) > -1
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Typography>Laktacijos kreivė</Typography>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <Box sx={{ width: '100%', height: '100%' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
              data={duomenys}
              margin={{ bottom: 16, left: 15, right: 10 }}
              // width={500}
              // height={400}
            >
              <CartesianGrid />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                content={<CustomTooltip pavadinimas={rodiklis.pavadinimas} />}
              />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ paddingBottom: '20px' }}
              />
              <XAxis
                type='number'
                dataKey='laktDiena'
                label={{
                  value: 'Laktacijos diena',
                  position: 'insideBottom',
                  offset: -10,
                }}
                ticks={[100, 200, 300]}
              />
              <YAxis
                name={rodiklis.pavadinimas}
                type='number'
                unit={rodiklis.vienetai}
                label={{
                  value: rodiklis.pavadinimas,
                  angle: -90,
                  position: 'insideLeft',
                  offset: -5,
                }}
              />
              {pikas > 0 && (
                <ReferenceLine
                  y={pikas}
                  stroke='red'
                  strokeDasharray='3 3'
                  ifOverflow='extendDomain'
                >
                  <Label
                    stroke='red'
                    strokeOpacity={0.7}
                    position='insideBottomRight'
                    value={`Maksimali reikšmė: ${pikas}`}
                  />
                </ReferenceLine>
              )}
              {pasirinktosLaktacijos.map((d) => (
                <Line
                  key={d.id}
                  name={pasirinktosLaktacijos.find((l) => l.id === d.id).label}
                  type='monotone'
                  dataKey={d.trPavad}
                  stroke={Spalvos[d.id]}
                  dot={false}
                  strokeWidth={6}
                  isAnimationActive={lineAnimation}
                />
              ))}
              {karve !== '' && (
                <Scatter name={karve} dataKey={karve} fill='black' />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
};

export default UkioLaktacijuGrafikas;
