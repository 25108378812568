export const MitybosRBaprasymai = [
  { grupe:'A1', aprasymas: 'Šioje grupėje dažniausiai būna antsvorio turinčios (nutukusios) karvės, su dideliais primilžiais per pirmus tris laktacijos mėnesius. Tokios karvės dėl sutrikusios maisto medžiagų apykaitos po veršiavimosi naudoja savo kūno riebalų atsargas ir greitai liesėja. Tai gali turėti neigiamos įtakos jų sveikatingumui ir pieno gamybos genetinio potencialo pasireiškimui. Šios grupės karvėms racione gali trūkti baltymų kad būtų patenkinti jų poreikiai. Taip pat gali trūkti gliukozės. Tokios karvės dažnai yra linkusios į maisto medžiagų apykaitos ligas, ketozę, sunkiau apsivaisina, jas tenka brokuoti.'},
  { grupe:'A2', aprasymas: 'Šioje grupėje dažniausiai būna karvės turinčios antsvorį po veršiavimosi. Joms būdingi dideli pieno primilžiai. Be karvių, kurios veršiavosi prieš 2-3 mėnesius, šioje grupėje gali būti karvės, kurios veršiavosi vos prieš mėnesį. Baltymų apykaita jų organizme gali būti normali, o riebalų – sutrikusi. Laktacijos pradžioje baltymų kiekis tokių karvių piene bus įprastas. Tačiau po kurio laiko, dėl energijos trūkumo karvėms liesėjant, gali sutrikti ir baltymų pasisavinimas bei sintezė. Tuomet karvės pereis į A1 karvių grupę. Tokios karvės linkusios į ketozę. Galimi jų kiaušidžių veiklos sutrikimai ir problemos dėl apvaisinimo. Šios grupės karvės gali liesėti, gali sutrikti jų pieno sintezė, sumažėti imunitetas. Šioje grupėje taip pat gali būti karvės nuo 4 iki 10 savaičių po veršiavimosi, kurios šeriamos aukštos kokybės pašarų mišiniais. Tokie atvejai dar nėra problematiški.'},
  { grupe:'A3', aprasymas: 'Šioje grupėje karvės gali būti išskirtiniais atvejais. Daugiausia tai mažo produktyvumo karvės. Jų pieno sudėtis paprastai rodo, kad šėrimas subalansuotas, aprūpinimas energija ir baltymais pakankamas. Laktacijos pradžioje prarastas organizmo riebalų atsargas atstatyti tokioms karvėms pavyksta lengviau, jei buvo tinkama jų priežiūra ir mityba užtrūkinimo laikotarpiu. Tačiau reikia atkreipti dėmesį į tai, kad užtrūkintos mažo produktyvumo karvės, net ir gaudamos vidutinio maistingumo racioną, gali nutukti ir turėti problemų sekančios laktacijos metu. Šioje grupėje dar gali būti pirmame laktacijos mėnesyje esančios karvės (šviežiapienės), tačiau tokie atvejai reti. Dar viena iš galimų priežasčių, kad karvės pateko į šią grupę, yra ta, kad tyrimams buvo atiduotas karvių priešpienio (krekenų) mėginys, kurio sudėtis skiriasi nuo natūralaus pieno.'},
  { grupe:'B1', aprasymas: 'Į šią grupę patenka karvės, esančios nuo 1 iki 5 laktacijos mėnesiuose ir duodančios daug pieno. Tai gali būti riboto genetinio produktyvumo potencialo karvės, kurios nėra pajėgios maksimaliai pasisavinti ir panaudoti produkcijai gaminti su pašarais gaunamą energiją ir baltymus. Tokių karvių pieno R/B santykis yra normalus, tačiau pašarų virškinamumas ir medžiagų apykaita nėra pakankamai geri. Todėl jų kūno riebalų atsargų atstatymas taip pat sutrinka. Šios grupės karvės praranda svorį, suvargsta, pasižymi mažesniu atsparumu įvairioms infekcijoms (mastitui). Taip pat tokias karves gali dažnai troškinti ir gali būti su tuo susijusių mitybos problemų.'},
  { grupe:'B2', aprasymas: 'Šios grupės karvių energijos ir baltymų apykaita normali, koncentruotų pašarų struktūra tinkama. Nuo ketvirto laktacijos mėnesio karvės suvartojamų pašarų kiekis sumažėja (lyginant su pirmais laktacijos mėnesiais), tačiau atitinka jų poreikius. Karvės, esančios šioje grupėje, visos laktacijos metu optimaliai išnaudoja genetinį produktyvumo potencialą. Jos pasižymi gera reprodukcija, atsparumu ligoms, gera savijauta ir ilgaamžiškumu. Vienintelė problema – šioje grupėje esančios mažesnio produktyvumo karvės taip pat gali būti problemiškos mitybos atžvilgiu.'},
  { grupe:'B3', aprasymas: 'Šioje grupėje dažniausiai yra laktacijos pabaigoje esančios karvės (nuo 8-to laktacijos mėnesio). Jų pieno sudėtis paprastai rodo, kad šėrimas subalansuotas, aprūpinimas energija ir baltymais pakankamas. Laktacijos pradžioje prarastas organizmo riebalų atsargas atstatyti tokioms karvėms pavyksta, jei buvo tinkama jų priežiūra ir mityba užtrūkinimo laikotarpiu. B3 grupėje dar gali būti pirmame laktacijos mėnesyje esančios karvės. Tačiau tai pasitaiko labai retai. Viena iš galimų karvių priskyrimo šiai grupei priežasčių yra ta, kad tyrimams buvo atiduotas karvių priešpienio (krekenų) mėginys.'},
  { grupe:'C1', aprasymas: 'Šioje grupėje būna karvės su virškinimo trakto sutrikimais (klinikinė ir subklinikinė acidozė). Tai karvės su dideliais primilžiais per pirmus 4 laktacijos mėnesius. Pagrindinė tokių karvių virškinimo sutrikimų priežastis – bloga raciono struktūra, blogos kokybės pašarai ir šėrimo tvarka, eiliškumas (staigus šėrimo pakeitimas, ypač – perėjimas prie labiau koncentruoto pašaro). Tokioms karvėms būdingi dideli primilžių svyravimai. Jos suėda mažiau pašarų, dažnai net neėda koncentruotų pašarų. Dėl nepakankamo pašarų ėdamumo ir blogo virškinimo yra didelė antrinės ketozės (ketozės, kurią sukelia acidozė) rizika. Tokios karvės turi problemų dėl nagų ligų (lėtinio laminito), stipriau ar silpniau išreikšto viduriavimo, jos liesėja, yra sunkiai pagydomos. Taip pat galimos grėsmės dėl šliužo dislokacijos, gleivinės pažeidimų, infekcijų, kepenų uždegimo. Tokioms karvėms būdinga bendras imuniteto nusilpimas, chroniški susirgimai, mažėjantis produktyvumas.'},
  { grupe:'C2', aprasymas: 'Šios grupės karvės gauna blogos struktūros racioną (jame per didelis kiekis koncentruotų pašarų ir trūksta ląstelienos). Šių karvių šėrimo problemos yra panašios kaip C1 grupės karvių, išskyrus tai, kad C1 grupės karvės daugiausia yra laktacijos pradžioje (pirmi keturi laktacijos mėnesiai), o C2 grupėje gali būti visų laktacijos stadijų karvės. Tokioms karvėms dažnai pasireiškia virškinimo trakto sutrikimai (klinikinė ir subklinikinė acidozė). Karvės suėda per mažai pašarų, kartais neėda koncentruotų pašarų. Dažnai tokiais atvejais sutrinka pašarų virškinamumas ir maisto medžiagų pasisavinimas (dėl prieskrandžio turinio parūgštėjimo). Tokios karvės turi problemų dėl nagų ligų (lėtinio laminito). Taip pat galimos grėsmės dėl šliužo dislokacijos, gleivinės pažeidimų, infekcijų, kepenų uždegimo. Tokioms karvėms būdinga bendras imuniteto nusilpimas.'},
  { grupe:'C3', aprasymas: 'Problemos yra panašios kaip B3 grupėje esančių karvių. Tačiau gali būti, kad šios grupės karvės gauna blogos struktūros racioną (jame gali būti per didelis koncentruotų pašarų kiekis ir trūkti ląstelienos). Šios grupės karvės dažniausiai yra laktacijos pabaigoje (nuo 8-to laktacijos mėnesio). Jų pieno sudėtis rodo, kad racionas subalansuotas ir atitinka poreikius. Sumažėjęs tokių karvių pieno riebalų ir baltymų santykis rodo, kad jų prieskrandyje yra sumažėjęs acto ir propiono rūgšties santykis, kuris gali rodyti organizmo atsargų atsistatymo (išsekimo) ir su tuo susijusias problemas pirmais laktacijos mėnesiais.C3 grupėje dar gali būti pirmame laktacijos mėnesyje esančios karvės. Tačiau tai pasitaiko labai retai. Viena iš galimų karvių priskyrimo į šią grupę priežasčių yra ta, kad tyrimams buvo atiduotas karvių priešpienio (krekenų) mėginys.'}
];

export const MitybosUraprasymai = [
  { grupe:'A1', apibendrinimas: 'Baltymų trūkumas ir energijos perteklius pašare.', aprasymas: 'Baltymų trūkumas ir energijos perteklius pašare. Šioje grupėje būna mažai karvių. Daugiausia tai karvės, esančios vėlyvesnėje laktacijos stadijoje (nuo 7 laktacijos mėn.). Jų racione gali būti daug kukurūzų siloso arba silosas yra prastos kokybės. Šių karvių aprūpinimas energija yra tinkamas, tačiau racione trūksta prieskrandyje skaidomų baltymų. Todėl virškinimas prieskrandyje ir maistinių medžiagų pasisavinimas yra nepakankami. Nepaisant prasto maistinių medžiagų pasisavinimo, laktacijos pabaigoje tokioms karvėms didėja rizika dėl nutukimo.'},
  { grupe:'A2', apibendrinimas: 'Energijos perteklius pašare.', aprasymas: 'Energijos perteklius pašare.Šioje grupėje būna nedaug karvių. Daugiausia tai karvės vėlyvesnėje laktacijos stadijoje (nuo 7 laktacijos mėnesio), kai gyvulio poreikiai yra santykinai maži ir nesunkiai patenkinami. Baltymų ir energijos santykis racione yra normos ribose, tačiau pagal gyvūno poreikius tiek energijos, tiek virškinamųjų baltymų racione yra kiek per daug. Tokioms karvėms organizmo atsargų atstatymui bus ypač svarbi tinkama jų priežiūra ir mityba užtrūkinimo laikotarpiu.'},
  { grupe:'A3', apibendrinimas: 'Baltymų ir energijos perteklius pašare.', aprasymas: 'Baltymų ir energijos perteklius pašare. Šioje grupėje būna nedaug karvių. Daugiausia tai karvės vėlyvesnėje laktacijos stadijoje (nuo 7 laktacijos mėnesio). Šių karvių energijos ir virškinamųjų baltymų poreikiai yra palyginti nedideli ir gali būti lengvai patenkinami. Šios grupės karvių racione gali būti daug žolinių pašarų. Ypač didelis urėjos kiekis piene gali būti karvių, kurios šeriamos silosu iš žalių pašarų, kurie buvo gausiai tręšti azotu. Taip pat didelis urėjos kiekis piene gali būti, jei racione per didelis sojų ar rapso išspaudų ar jų rupinių kiekis. Tokiais atvejais didelis urėjos kiekis bus piene ir šlapime.'},
  { grupe:'B1', apibendrinimas: 'Baltymų trūkumas ir nedidelis energijos perteklius pašare.', aprasymas: 'Baltymų trūkumas ir nedidelis energijos perteklius pašare. Šioje grupėje daugiausia gali būti karvės nuo 3 iki 8 laktacijos mėnesio. Jų racione gali būti daug kukurūzų arba kukurūzų ir žolės siloso, prasta siloso kokybė ir nepakankamas kiekis grūdinių pašarų (kukurūzų, miežių, kviečių). Mažas urėjos kiekis piene rodo, kad prieskrandyje trūksta skaidomų baltymų. Todėl gyvuliai suvartoja per mažai pašaro, virškinimas jų prieskrandyje nėra pakankamas. Mikrobinių baltymų sintezė prieskrandyje taip pat nepakankama. Pašarų panaudojimas prastas ir neekonomiškas, o primilžis ir baltymingumas piene mažesnis nei galėtų būti.'},
  { grupe:'B2', apibendrinimas: 'Baltymų ir energijos balansas pašare.', aprasymas: 'Baltymų ir energijos balansas pašare. Šios grupės karvių turi būti daugiausia. Šiai grupei priskiriamos karvės, kurios gauna energijos ir baltymų atžvilgiu tinkamai subalansuotą racioną. Tai tinkamai prižiūrimos, didesnio genetinio produktyvumo potencialo karvės. Jų šėrimas optimalus, atitinka poreikius. Jei bus tenkinamos kitos sąlygos (geras veislinis darbas, pakankamas karvių aprūpinimas vitaminais ir mineralais, tinkama jų sveikatos priežiūra, užtikrinta gerovė) tai karvės, esančios šioje grupėje, bus produktyvios, vislios, atsparios ligoms ir bus ilgaamžės.'},
  { grupe:'B3', apibendrinimas: 'Baltymų perteklius ir nedidelis energijos trūkumas pašare.', aprasymas: 'Baltymų perteklius ir nedidelis energijos trūkumas pašare. Šios grupės karvių racionas subalansuotas ir atitinka jų poreikius energijos ir virškinamųjų baltymų aprūpinimo atžvilgiu. Tačiau racione yra per didelis skaidomų baltymų kiekis. Šios grupės karvės gauna daug žolinių ir koncentruotų pašarų. Lengvai skylančių baltymų perteklius prieskrandyje apsunkina karvių medžiagų apykaitą, ypač kepenų veiklą, ir gali būti viena iš prasto vaisingumo rodiklių priežasčių.'},
  { grupe:'C1', apibendrinimas: 'Baltymų ir energijos trūkumas pašare.', aprasymas: 'Baltymų ir energijos trūkumas pašare. Šioje grupėje būna palyginti nedaug karvių. Daugiausia tai karvės per pirmuosius 3 laktacijos mėnesius po apsiveršiavimo. Gali būti, kad jos racione gauna daug kukurūzų siloso arba racionai su kukurūzų, žolės silosu yra netinkamai papildomi (šiuo atveju naudojamas per mažas jų kiekis) grūdiniais pašarais (kukurūzais, miežiais, kviečiais). Mažas urėjos kiekis piene rodo, kad prieskrandyje trūksta skaidomų baltymų. Gyvūnai sunaudoja per mažai pašaro. Virškinimas ir mikrobinių baltymų sintezė prieskrandyje yra nepakankami. Nėra užtikrinamas gyvūnų aprūpinimas virškinamaisiais baltymais ir energija. Dėl vienpusės raciono sudėties gali būti nepasiektas nei genetinis, nei nuo mitybos priklausantis gyvulio produktyvumo potencialas.'},
  { grupe:'C2', apibendrinimas: 'Energijos trūkumas pašare.', aprasymas: 'Energijos trūkumas pašare. Šioje grupėje būna palyginti daug karvių. Daugiausia tai karvės per pirmuosius keturis laktacijos mėnesius po apsiveršiavimo. Šios grupės karvės gauna energijos ir baltymų atžvilgiu subalansuotą racioną (skaidomų baltymų ir fermentuojamos energijos santykis yra tinkamas), bet bendras karvių aprūpinimas energija ir virškinamaisiais baltymais yra kiek per kuklus pagal gyvūno poreikius.'},
  { grupe:'C3', apibendrinimas: 'Baltymų perteklius ir energijos trūkumas pašare.', aprasymas: 'Baltymų perteklius ir energijos trūkumas pašare. Atsižvelgiant į mitybos normas šios grupės karvių racione trūksta energijos ir virškinamųjų baltymų, yra per daug prieskrandyje skaidomų baltymų. Šios grupės karvėms būdingi dideli primilžiais. Jos racione gauna daug žolinių ir koncentruotų pašarų. Tiek nepakankamas virškinamųjų baltymų ir energijos kiekis, tiek skaidomų baltymų perteklius prieskrandyje apsunkina tokių karvių medžiagų apykaitą, ypač apkrauna kepenis. Iš jų galima tikėtis vaisingumo problemų.'}
];