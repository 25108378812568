import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import { StyledPaper } from '../shared/StyledComponents';
import { HtmlTooltip } from '../mitybosVertinimas/MitybosSuvestine';
import KoeficientoPranesimas from './KoeficientoPranesimas';

const UkioLaktacijuLentele = ({ pikas, reiksme90, santykis }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <StyledPaper sx={{ gap: '20px' }}>
          <Box>
            <Typography>
              <b>{pikas.diena}</b>
            </Typography>
            <Typography>Laktacijos piko diena</Typography>
          </Box>
          <Box>
            <Typography>
              <b>{pikas.reiksme}</b>
            </Typography>
            <Typography>Rodiklio piko reikšmė</Typography>
          </Box>
          <Box>
            <Typography>
              <b>{reiksme90}</b>
            </Typography>
            <Typography>90-os dienos rodiklio reikšmė</Typography>
          </Box>
          <Box>
            <Typography>
              <b>{`${santykis} %`}</b>
              <IconButton onClick={() => setOpen(true)}>
                <InfoIcon color='primary' />
              </IconButton>
            </Typography>
            <Typography display='flex' alignItems='center'>
              Laktacijos kreivės tolygumo koeficientas
              <HtmlTooltip title='Kreivės reikšmių sumos pokytis tarp 100d. ir 200d.'>
                <HelpOutlineIcon />
              </HtmlTooltip>
            </Typography>
          </Box>
        </StyledPaper>
      </Box>
      <KoeficientoPranesimas isOpen={open} handleClose={handleClose} />
    </>
  );
};

export default UkioLaktacijuLentele;
