import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { StyledPaper } from '../shared/StyledComponents';

const LaktacijuLentele = ({ isLoading, pikas }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '100%',
      }}
    >
      <StyledPaper alignitems='center' sx={{ gap: '10px' }}>
        {isLoading ? (
          <>
            <Skeleton height={25} width={30} />
            <Skeleton height={25} width='90%' />
          </>
        ) : (
          <>
            <Typography>
              <b>{pikas.diena}</b>
            </Typography>
            <Typography>Maksimalaus primilžio diena</Typography>
          </>
        )}
      </StyledPaper>
      <StyledPaper alignitems='center' sx={{ gap: '10px' }}>
        {isLoading ? (
          <>
            <Skeleton height={25} width={30} />
            <Skeleton height={25} width='90%' />
          </>
        ) : (
          <>
            <Typography>
              <b>{pikas.reiksme}</b>
            </Typography>
            <Typography>Maksimalus primilžis (pikas), P(kg)</Typography>
          </>
        )}
      </StyledPaper>
    </Box>
  );
};

export default LaktacijuLentele;
