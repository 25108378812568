import React from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { Spalvos } from '../../constants/Spalvos';
import { Box, Typography } from '@mui/material';
import { StyledLabel, StyledTooltip } from '../shared/StyledComponents';
import RodiklioSelect from '../shared/RodiklioSelect';
import { MitybosRodikliai } from '../../constants/Rodikliai';
import { MitybosBaltGrupes } from '../../Pages/MitybosVertinimas';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`Karvės Nr.: ${payload[1].payload.karNr}`}</StyledLabel>
        <StyledLabel>{`Kontr. data: ${payload[1].payload.kontrData}`}</StyledLabel>
        <StyledLabel>{`Mėnuo: ${payload[1].payload.menuo}`}</StyledLabel>
        <StyledLabel>{`Lakt. diena: ${payload[1].payload.laktDiena}`}</StyledLabel>
        <StyledLabel>{`${payload[0].name}: ${payload[0].value}`}</StyledLabel>
        <StyledLabel>{`${payload[1].name}: ${payload[1].value}`}</StyledLabel>
      </StyledTooltip>
    );
  }

  return null;
};

const referenceLinesRb = [
  { x: 3.2, y: null, position: 'insideTopRight', label: '3.2%' },
  { x: 3.6, y: null, position: 'insideTopLeft', label: '3.6%' },
  { x: null, y: 1.0, position: 'insideTopLeft', label: '1' },
  { x: null, y: 1.4, position: 'insideBottomLeft', label: '1.4' },
];

const referenceLinesUrea = [
  { x: 15, y: null, position: 'insideTopRight', label: '15' },
  { x: 30, y: null, position: 'insideTopLeft', label: '30' },
  { x: null, y: 3.2, position: 'insideTopLeft', label: '3.2%' },
  { x: null, y: 3.6, position: 'insideBottomLeft', label: '3.6%' },
];

const MitybosGrafikas = ({ isLoading, duomenys, rodiklis, setRodiklis }) => {
  const grupes =
    rodiklis.parametras === 'rb'
      ? [...new Set(duomenys.map((d) => d.grPavad))].sort()
      : [...new Set(duomenys.map((d) => d.grPavadUr))].sort();
  const duomenuArr = [];
  if (duomenys) {
    if (rodiklis.parametras === 'rb') {
      for (let i = 0; i < grupes.length; i++) {
        duomenuArr[i] = {
          grupe: grupes[i],
          data: duomenys.filter((d) => d.grPavad === grupes[i]),
        };
      }
    } else {
      for (let i = 0; i < grupes.length; i++) {
        duomenuArr[i] = {
          grupe: grupes[i],
          data: duomenys.filter((d) => d.grPavadUr === grupes[i]),
        };
      }
    }
  }

  const lines =
    rodiklis.parametras === 'rb' ? referenceLinesRb : referenceLinesUrea;

  return (
    <>
      {/* <Typography>R/B ir B(%) pasiskirstymas</Typography> */}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '8px',
          mb: 1,
          width: '100%',
        }}
      >
        <RodiklioSelect
          rodiklis={rodiklis}
          rodikliuList={MitybosRodikliai}
          changeRodiklis={setRodiklis}
        />
        <Typography>pasiskirstymas</Typography>
      </Box>
      {isLoading ? (
        <Box
          width='100%'
          height={500}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height={500}>
          <ScatterChart margin={{ bottom: 15, left: 15 }}>
            <CartesianGrid />
            <XAxis
              type='number'
              dataKey={rodiklis.xValue}
              name={rodiklis.xLabel}
              domain={
                rodiklis.parametras === 'rb'
                  ? [1, 'dataMax + 0.5']
                  : [0, 'dataMax + 5']
              }
              label={{
                value: rodiklis.xLabel,
                position: 'insideBottom',
                offset: -10,
              }}
            />
            <YAxis
              type='number'
              dataKey={rodiklis.yValue}
              name={rodiklis.yLabel}
              domain={
                rodiklis.parametras === 'rb'
                  ? [0.4, (dataMax) => (dataMax + 0.1).toFixed(1)]
                  : [1, (dataMax) => (dataMax + 0.5).toFixed(1)]
              }
              label={{
                value: rodiklis.yLabel,
                angle: -90,
                position: 'insideLeft',
                offset: -5,
              }}
            />
            <ZAxis range={[80, 81]} />
            {lines.map((l, index) => (
              <ReferenceLine
                key={`refLine-${index}`}
                x={l.x && l.x}
                y={l.y && l.y}
                stroke='black'
                strokeDasharray='3 3'
                strokeWidth={1.5}
                ifOverflow='extendDomain'
              >
                <Label position={l.position} value={l.label} />
              </ReferenceLine>
            ))}
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip />}
            />
            <Legend
              align='right'
              layout='vertical'
              verticalAlign='top'
              wrapperStyle={{ paddingLeft: '20px' }}
            />
            {duomenuArr.map((g) => (
              <Scatter
                key={g.grupe}
                name={g.grupe}
                data={g.data}
                fill={
                  Spalvos[
                    MitybosBaltGrupes.map((m) => m.label).indexOf(g.grupe)
                  ]
                }
                opacity={0.6}
              />
            ))}
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default MitybosGrafikas;
