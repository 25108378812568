import React from 'react';
import GrupiuSelectItem from '../shared/GrupiuSelectItem';
import { useGetQuery } from '../../hooks/useQuery';
import { useAuth } from '../../context/AuthProvider';
import { useMenu } from '../../context/MenuProvider';
import {
  LaktacijuAmziai,
  LaktacijuTrukmes,
} from '../../constants/ParametruGrupes';
import { Box } from '@mui/material';
import VeisliuSelect from '../home/VeisliuSelect';
import SingleSelect from '../shared/SingleSelect';
import { kontrolesPeriodai } from '../../constants/KontrolesPeriodai';
import { MitybosBaltGrupes } from '../../Pages/MitybosVertinimas';

const MitybosPasirinkimai = ({
  bandos,
  setBandos,
  menesis,
  setMenesis,
  grupes,
  setGrupes,
  laktacijos,
  setLaktacijos,
  veisliuList,
  veisles,
  setVeisles,
  laktTrukmes,
  setLaktTrukmes,
  rodiklis,
}) => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === 'Ūkis';

  const banduList = useGetQuery(
    arSavininkas ? 'bandos' : 'bandos/' + menu.savininkas
  ).map((b) => {
    return { id: b.banda, label: b.banda };
  });

  const menesiai = kontrolesPeriodai.map((k) => {
    return { id: k, label: k };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
      }}
    >
      <Box key='bandos'>
        <GrupiuSelectItem
          pavadinimas='Bandos'
          grupes={banduList}
          pasirinktos={bandos}
          changeGrupes={setBandos}
          fullWidth
        />
      </Box>
      <Box key='menesis'>
        <SingleSelect
          pavadinimas='Metai ir mėnuo'
          pasirinkimai={menesiai}
          reiksme={menesis}
          changeReiksme={setMenesis}
        />
      </Box>
      <Box key='grupes'>
        <GrupiuSelectItem
          pavadinimas={`${rodiklis} grupės`}
          grupes={MitybosBaltGrupes}
          pasirinktos={grupes}
          changeGrupes={setGrupes}
          fullWidth
        />
      </Box>
      <Box key='laktAmziai'>
        <GrupiuSelectItem
          pavadinimas='Laktacijos amžius'
          grupes={LaktacijuAmziai}
          pasirinktos={laktacijos}
          changeGrupes={setLaktacijos}
          fullWidth
        />
      </Box>
      <Box key='laktTrukmes'>
        <GrupiuSelectItem
          pavadinimas='Laktacijos trukmės'
          grupes={LaktacijuTrukmes}
          pasirinktos={laktTrukmes}
          changeGrupes={setLaktTrukmes}
          fullWidth
        />
      </Box>
      <Box key='veisles'>
        <VeisliuSelect
          veisliuList={veisliuList}
          veisles={veisles}
          changeVeisles={setVeisles}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default MitybosPasirinkimai;
