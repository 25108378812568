import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import DrawerMenu from './DrawerMenu';
import { useAuth } from '../context/AuthProvider';
import VadovoMeniu from './layout/VadovoMeniu';
import AsistentoMeniu from './layout/AsistentoMeniu';
import { useMenu } from '../context/MenuProvider';

const Layout = () => {
  const menu = useMenu();
  const auth = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rajonas, setRajonas] = useState(menu.rajonas);
  const [savininkas, setSavininkas] = useState(menu.savininkas);

  const toggleDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  const hadleChangeRajonas = (rajId) => {
    setRajonas(rajId);
    menu.changeRajonas(rajId);
    setSavininkas('');
    menu.changeSavininkas('');
  };

  const handleChangeSavininkas = (savId) => {
    setSavininkas(savId);
    menu.changeSavininkas(savId);
  };

  return (
    <>
      <Box display='flex' flexDirection='row' height='100vh' width='100vw'>
        <Header toggleDrawer={toggleDrawer} />
        <DrawerMenu open={drawerOpen} toggleDrawer={toggleDrawer} />
        <Box
          component='main'
          display='flex'
          flexGrow='1'
          overflow='auto'
          flexDirection='column'
          sx={{ paddingTop: '80px' }}
        >
          {auth.user.role === 'Vadovas' &&
            auth.screen !== '/laktacijuKreives' && (
              <VadovoMeniu
                rajonas={rajonas}
                savininkas={savininkas}
                changeRajonas={hadleChangeRajonas}
                changeSavininkas={handleChangeSavininkas}
              />
            )}
          {auth.user.role === 'Asistentas' &&
            auth.screen !== '/laktacijuKreives' && (
              <AsistentoMeniu
                savininkas={savininkas}
                changeSavininkas={handleChangeSavininkas}
              />
            )}
          <Container maxWidth='100vw'>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
