import React, { useState } from 'react';
import {
  Checkbox,
  ListItemText,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Box,
  Skeleton,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FixedSizeList } from 'react-window';
import { useAuth } from '../../context/AuthProvider';
import styled from '@emotion/styled';
import { useGetQuery } from '../../hooks/useQuery';

const KarvesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 4px;
`;

const Karves = ({
  isLoading,
  savId,
  bandos,
  karves,
  changeKarves,
  handleZymVisasKarves,
}) => {
  const [zymVisos, setZymVisos] = useState(true);
  const [searchText, setSearchText] = useState('');
  const auth = useAuth();
  const arSavininkas = auth.user.role === 'Ūkis';

  const karviuList = useGetQuery(arSavininkas ? 'karves' : 'karves/' + savId);

  let banduKarves =
    bandos.length > 0
      ? karviuList.filter((k) => bandos.includes(k.banda))
      : karviuList;

  searchText !== '' &&
    (banduKarves = banduKarves.filter((k) => k.karNr.includes(searchText)));

  const handleZymKarve = (target) => {
    changeKarves(target.checked, target.value);
    if (target.checked && searchText === '') {
      setZymVisos(karves.length === banduKarves.length);
    } else {
      setZymVisos(false);
    }
  };

  const handleZymVisas = (checked) => {
    if (checked) {
      setZymVisos(true);
      handleZymVisasKarves(banduKarves.map((karve) => karve.karNr));
    } else {
      setZymVisos(false);
      handleZymVisasKarves([]);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setZymVisos(false);
    changeKarves([]);
  };

  const renderRow = (props) => {
    const { data, index, style } = props;

    if (data[index] === undefined) {
      return null;
    }

    return (
      <ListItem style={style} key={index} component='div' disablePadding>
        <ListItemButton
          onClick={
            index === 0 && searchText === ''
              ? (e) => handleZymVisas(e.target.checked)
              : (e) => handleZymKarve(e.target)
          }
        >
          <ListItemIcon>
            <Checkbox
              checked={karves.indexOf(data[index].karNr) > -1 || zymVisos}
              value={data[index].karNr}
              inputProps={{ 'aria-labelledby': data[index].karNr }}
            />
          </ListItemIcon>
          <ListItemText primary={data[index].karNr} />
        </ListItemButton>
      </ListItem>
    );
  };

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '100%',
            marginBottom: '4px',
          }}
        >
          <Skeleton width='50%' height={30} />
          {[...Array(5).keys()].map((s) => (
            <Box
              key={s}
              sx={{ display: 'flex', gap: '20px', paddingLeft: '30px' }}
            >
              <Skeleton width={30} height={30} />
              <Skeleton width='100%' />
            </Box>
          ))}
        </Box>
      </>
    );
  }

  if (karviuList.length === 0) {
    return <Typography>Karvės</Typography>;
  }

  return (
    <>
      <KarvesContainer>
        <Typography>{karves ? 'Karvės' : 'Pasirinkite karves'}</Typography>
        <TextField
          id='karNr'
          name='karNr'
          type='text'
          value={searchText}
          size='small'
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              borderRadius: '12px',
              height: '24px',
              paddingLeft: '10px',
            },
          }}
        />
      </KarvesContainer>
      {banduKarves.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography color='red'>Karvių su tokiu numeriu nerasta</Typography>
        </Box>
      ) : (
        <FixedSizeList
          height={300}
          width={230}
          itemData={
            searchText === ''
              ? [{ karNr: 'Žymėti visas' }, ...banduKarves]
              : banduKarves
          }
          itemSize={50}
          itemCount={
            banduKarves.length !== 'undefined' ? banduKarves.length + 1 : 0
          }
          style={{ scrollbarWidth: 'thin' }}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </>
  );
};

export default Karves;
