import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Primary } from '../../constants/Spalvos';

const PieColors = [Primary, '#8e8989', '#3d3e40', '#060606'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  index,
  payload,
}) => {
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (payload.count === 0) return null;

  return (
    <text
      x={x}
      y={y}
      fill={PieColors[index]}
      textAnchor={x > cx ? 'start' : 'end'}
    >
      {`${payload.count}(${(percent * 100).toFixed(0)}%)`}
    </text>
  );
};

const SveikatSkritDiagrama = ({ isLoading, pavadinimas, duomenys }) => {
  const data = duomenys.filter((d) => d.count > 0);

  return (
    <>
      <Typography>{pavadinimas}</Typography>
      {isLoading ? (
        <Box
          width='100%'
          height='100%'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart margin={{ left: 20, right: 20 }}>
            <Pie
              dataKey='count'
              data={data}
              // label
              label={renderCustomizedLabel}
              innerRadius='60%'
              legendType='circle'
              startAngle={90}
              endAngle={450}
            >
              {duomenys.map((d, index) => (
                <Cell key={`cell-${index}`} fill={PieColors[index]} />
              ))}
            </Pie>
            <Legend layout='horizontal' align='bottom' />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default SveikatSkritDiagrama;
