import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Primary } from '../../constants/Spalvos';

const BarColors = [Primary, '#8e8989', '#3d3e40', '#060606'];

const SlsStulpeliuGrafikas = ({ isLoading, pavadinimas, data, keys }) => {
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      {isLoading ? (
        <Box
          width='100%'
          height='100%'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart data={data} width='100%' height='100%'>
            <CartesianGrid />
            <XAxis dataKey='menuo' />
            <YAxis />
            <Tooltip />
            <Legend
              layout='vertical'
              align='right'
              verticalAlign='middle'
              wrapperStyle={{ paddingLeft: '20px' }}
            />
            {keys.map((k, index) => (
              <Bar
                key={`bar-${index}`}
                dataKey={k}
                stackId='a'
                fill={BarColors[index]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default SlsStulpeliuGrafikas;
