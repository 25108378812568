import React from 'react';
import { useGetQuery } from '../../hooks/useQuery';
import { useAuth } from '../../context/AuthProvider';
import { useMenu } from '../../context/MenuProvider';
import { Box } from '@mui/material';
import SingleSelect from '../shared/SingleSelect';
import {
  LaktacijuAmziai,
  LaktacijuTrukmes,
} from '../../constants/ParametruGrupes';

const SlsPasirinkimai = ({
  banda,
  setBanda,
  veisles,
  veisle,
  setVeisle,
  laktacija,
  setLaktacija,
  laktTrukme,
  setLaktTrukme,
}) => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === 'Ūkis';
  const banduList = useGetQuery(
    arSavininkas ? 'bandos' : 'bandos/' + menu.savininkas
  );

  const bandos = banduList.map((b) => ({
    id: b.banda,
    label: String(b.banda),
  }));
  bandos.unshift({ id: 0, label: 'Visos' });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
      }}
    >
      <Box key='bandos'>
        <SingleSelect
          pavadinimas='Banda'
          pasirinkimai={bandos}
          reiksme={banda}
          changeReiksme={setBanda}
        />
      </Box>
      <Box key='veisles'>
        <SingleSelect
          pavadinimas='Veislė'
          pasirinkimai={veisles}
          reiksme={veisle}
          changeReiksme={setVeisle}
        />
      </Box>
      <Box key='laktacija'>
        <SingleSelect
          pavadinimas='Amžius laktacijoms'
          pasirinkimai={LaktacijuAmziai}
          reiksme={laktacija}
          changeReiksme={setLaktacija}
        />
      </Box>
      <Box key='laktTrukme'>
        <SingleSelect
          pavadinimas='Laktacijos trukmė'
          pasirinkimai={LaktacijuTrukmes}
          reiksme={laktTrukme}
          changeReiksme={setLaktTrukme}
        />
      </Box>
    </Box>
  );
};

export default SlsPasirinkimai;
