import { MenuItem, Select, Typography } from '@mui/material';
import { useGetQuery } from '../../hooks/useQuery';

const Rajonai = ({ rajId, changeRajonas }) => {
  const rajonai = useGetQuery('rajonai');

  return (
    <>
      <Typography>{rajId ? 'Rajonas:' : 'Pasirinkite rajoną:'}</Typography>
      <Select
        id='rajonoSelect'
        value={rajId}
        label=''
        fullWidth
        onChange={(e) => changeRajonas(e.target.value)}
        sx={{ textAlign: 'left' }}
        size='small'
      >
        <MenuItem key='empty' disabled value='0'>
          <em>Pasirinkti</em>
        </MenuItem>
        {rajonai.map((rajonas) => (
          <MenuItem key={rajonas.id} value={rajonas.id}>
            {rajonas.id + '.' + rajonas.pavadinimas}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Rajonai;
