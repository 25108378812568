import React from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Spalvos } from '../../constants/Spalvos';

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}));

const GrupesRB = [
  'A1. R/B > 1.4; B(%) < 3.2',
  'B1. R/B > 1-1.4; B(%) < 3.2',
  'C1. R/B < 1; B(%) < 3.2',
  'A2. R/B > 1.4; B(%) 3.2-3.6',
  'B2. R/B > 1-1.4; B(%) 3.2-3.6',
  'C2. R/B < 1; B(%) 3.2-3.6',
  'A3. R/B > 1.4; B(%) > 3.6',
  'B3. R/B > 1-1.4; B(%) > 3.6',
  'C3. R/B < 1; B(%) > 3.6',
];
const GrupesUrea = [
  'A1. B(%) > 3.6; U(mg%) < 15',
  'B1. B(%) 3.2-3.6; U(mg%) < 15',
  'C1. B(%) < 3.2; U(mg%) < 15',
  'A2. B(%) > 3.6; U(mg%) 15-30',
  'B2. B(%) 3.2-3.6; U(mg%) 15-30',
  'C2. B(%) < 3.2; U(mg%) 15-30',
  'A3. B(%) > 3.6; U(mg%) > 30',
  'B3. B(%) 3.2-3.6; U(mg%) > 30',
  'C3. B(%) < 3.2; U(mg%) > 30',
];

const MitybosSuvestine = ({ isLoading, duomenys, rodiklis }) => {
  const grupes = {
    A1: 0,
    A2: 0,
    A3: 0,
    B1: 0,
    B2: 0,
    B3: 0,
    C1: 0,
    C2: 0,
    C3: 0,
  };

  rodiklis === 'rb'
    ? duomenys.map((d) => grupes[`${d.grPavad}`]++)
    : duomenys.map((d) => grupes[`${d.grPavadUr}`]++);

  const data = Object.keys(grupes).map((item, index) => {
    return { id: index, label: item, value: grupes[item] };
  });

  data.sort((a, b) => b.value - a.value);

  const grupiuText = rodiklis === 'rb' ? GrupesRB : GrupesUrea;

  return (
    <>
      <Typography>Karvių sk. grupėse</Typography>
      {isLoading ? (
        <Box
          width='100%'
          height={400}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height={400}>
          <BarChart data={data} layout='vertical' margin={{ right: 30 }}>
            <XAxis type='number' />
            <YAxis dataKey='label' type='category' />
            <Bar
              dataKey='value'
              barSize={20}
              label={{ position: 'right', fill: 'black' }}
            >
              {data.map((entry, index) => (
                <Cell
                  fill={Spalvos[data.find((d) => d.id === entry.id).id]}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      <Typography>
        <b>Naudojamas skirstymasis grupėmis</b>
      </Typography>
      <Grid container>
        {[0, 1, 2].map((d) => (
          <Grid key={`grid-${d}`} item xs={12} lg={4}>
            {grupiuText.slice(d * 3, (d + 1) * 3).map((g) => (
              <HtmlTooltip key={g} title={g}>
                <Typography noWrap>{g}</Typography>
              </HtmlTooltip>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MitybosSuvestine;
