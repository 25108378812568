import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { postItem } from '../../Api/Api';
import { StyledPaper } from '../shared/StyledComponents';
import { useAuth } from '../../context/AuthProvider';

const skeletonHeight = 26;

const Stulpelis = ({ reiksme, parametras }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        width: '50%',
      }}
    >
      <Typography>
        <b>{reiksme}</b>
      </Typography>
      <Typography>{parametras}</Typography>
    </Box>
  );
};

const StulpelisSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        width: '50%',
      }}
    >
      <Skeleton width={40} height={skeletonHeight} />
      <Skeleton width='90%' height={skeletonHeight} />
    </Box>
  );
};

const FlexRow = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const VidurkiuLentele = ({ savininkas, menesiai }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vidurkiai, setVidurkiai] = useState({
    primilzis: 0,
    bazinis: 0,
    riebalai: 0,
    baltymai: 0,
    laktoze: 0,
    urea: 0,
    sls: 0,
  });
  const auth = useAuth();
  const arSavininkas = auth.user.role === 'Ūkis';

  useEffect(() => {
    let requestBody = { menesiai: menesiai };
    !arSavininkas && (requestBody.gptKodas = savininkas);

    if (menesiai.length > 0) {
      setIsLoading(true);
      postItem('menesioProdVid', requestBody)
        .then((data) => {
          data.data && setVidurkiai(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          setIsLoading(false);
        });
    }
  }, [savininkas, menesiai, arSavininkas]);

  if (isLoading) {
    return (
      <StyledPaper sx={{ width: '100%', gap: '10px', height: '100%' }}>
        <Skeleton width='100%' height={skeletonHeight} />
        {[...Array(3).keys()].map((s, index) => (
          <FlexRow key={`1-${index}`}>
            <StulpelisSkeleton />
            <StulpelisSkeleton />
          </FlexRow>
        ))}
        <FlexRow>
          <StulpelisSkeleton />
        </FlexRow>
        <Skeleton width='100%' height={skeletonHeight} />
        {[...Array(3).keys()].map((s, index) => (
          <FlexRow key={`2-${index}`}>
            <StulpelisSkeleton />
            <StulpelisSkeleton />
          </FlexRow>
        ))}
      </StyledPaper>
    );
  }

  return (
    <StyledPaper sx={{ width: '100%', gap: '10px', height: '100%' }}>
      <Typography>
        <b>Vidutiniai bandos rodikliai per parą</b>
      </Typography>
      <FlexRow>
        <Stulpelis
          reiksme={vidurkiai.primilzis}
          parametras='Primilžis, P(kg)'
        />
        <Stulpelis
          reiksme={vidurkiai.bazinis}
          parametras='Bazinis primilžis, PB(kg)'
        />
      </FlexRow>
      <FlexRow>
        <Stulpelis
          reiksme={vidurkiai.baltymai}
          parametras='Baltymų kiekis, B(%)'
        />
        <Stulpelis
          reiksme={vidurkiai.riebalai}
          parametras='Riebalų kiekis, R(%)'
        />
      </FlexRow>
      <FlexRow>
        <Stulpelis reiksme={vidurkiai.sls} parametras='SLS' />
        <Stulpelis reiksme={vidurkiai.urea} parametras='Urėja, U(mg%)' />
      </FlexRow>
      <FlexRow>
        <Stulpelis
          reiksme={vidurkiai.laktoze}
          parametras='Laktozės kiekis, L(%)'
        />
      </FlexRow>
      <Typography>
        <b>Suminis primilžis per parą</b>
      </Typography>
      <FlexRow>
        <Stulpelis
          reiksme={vidurkiai.suminisViso}
          parametras='Visų dienų (kg)'
        />
        <Stulpelis reiksme={vidurkiai.suminis30} parametras='30-ą dieną (kg)' />
      </FlexRow>
      <FlexRow>
        <Stulpelis reiksme={vidurkiai.suminis60} parametras='60-ą dieną (kg)' />
        <Stulpelis reiksme={vidurkiai.suminis90} parametras='90-ą dieną (kg)' />
      </FlexRow>
      <FlexRow>
        <Stulpelis
          reiksme={vidurkiai.suminis200}
          parametras='200-ą dieną (kg)'
        />
        <Stulpelis
          reiksme={vidurkiai.suminis305}
          parametras='305-ą dieną (kg)'
        />
      </FlexRow>
    </StyledPaper>
  );
};

export default VidurkiuLentele;
