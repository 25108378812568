export const Rodikliai = [
  {parametras: 'primilzis', pavadinimas: 'Pieno primilžis', vienetai: 'kg'},
  {parametras: 'bazinis', pavadinimas: 'Bazinis primilžis', vienetai: 'kg'},
  {parametras: 'riebalai', pavadinimas: 'Riebalų kiekis, %', vienetai: '%'},
  {parametras: 'baltymai', pavadinimas: 'Baltymų kiekis, %', vienetai: '%'},
  {parametras: 'rb', pavadinimas: 'Riebalų ir baltymų santykis, R/B', vienetai: ''},
  {parametras: 'laktoze', pavadinimas: 'Laktozė, %', vienetai: '%'},
  {parametras: 'urea', pavadinimas: 'Urėja mg%', vienetai: 'mg%'},
  {parametras: 'sls', pavadinimas: 'SLS', vienetai: 'tūkst./ml'},
];

export const MitybosRodikliai = [
  { 
    parametras: 'rb', 
    pavadinimas: 'R/B ir B(%)', 
    xValue: 'baltymai', 
    xLabel: 'B(%)', 
    yValue: 'rb', 
    yLabel: 'R/B' 
  },
  { 
    parametras: 'urea', 
    pavadinimas: 'B(%) ir U(mg%)', 
    xValue: 'urea', 
    xLabel: 'U(mg%)', 
    yValue: 'baltymai', 
    yLabel: 'B(%)' },
];