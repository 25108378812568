import React from 'react';
import { useAuth } from '../context/AuthProvider';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const auth = useAuth();
  if (!auth.user) return <Navigate to='/login' />;
  return <Outlet />;
};

export default ProtectedRoute;
