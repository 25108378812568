import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';

const RibuInput = ({ ribos, reiksmes, changeReiksmes }) => {
  const [minError, setMinError] = useState('');
  const [maxError, setMaxError] = useState('');
  const [paramRibos, setParamRibos] = useState(reiksmes);

  const debounceValue = useDebounce(paramRibos, 500);

  useEffect(() => {
    changeReiksmes(paramRibos);
    paramRibos.min < ribos.ribos.min
      ? setMinError(
          `Įvesta per maža reikšmė, skaičiavimuose bus naudojama ${ribos.ribos.min}`
        )
      : setMinError('');

    paramRibos.max > ribos.ribos.max
      ? setMaxError(
          `Įvesta per didelė reikšmė, skaičiavimuose bus naudojama ${ribos.ribos.max}`
        )
      : setMaxError('');
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography noWrap>{ribos.pavadinimas}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            width: '100%',
          }}
        >
          <Box width='50%'>
            <TextField
              id='min'
              name='min'
              type='number'
              variant='outlined'
              value={paramRibos.min}
              size='small'
              fullWidth
              error={minError !== ''}
              onChange={(e) =>
                setParamRibos((prev) => ({
                  ...prev,
                  min: e.target.value,
                }))
              }
              title={minError && minError}
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
            />
          </Box>
          <Box width='50%'>
            <TextField
              id='max'
              name='max'
              type='number'
              variant='outlined'
              size='small'
              value={paramRibos.max}
              fullWidth
              title={maxError && maxError}
              error={maxError !== ''}
              onChange={(e) =>
                setParamRibos((prev) => ({
                  ...prev,
                  max: e.target.value,
                }))
              }
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RibuInput;
