import React, { useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
  Box,
} from '@mui/material';
import { useGetQuery } from '../../hooks/useQuery';

const Bandos = ({
  isLoading,
  savId,
  bandos,
  changeBandos,
  handleZymVisosBandos,
}) => {
  const [zymVisos, setZymVisos] = useState(true);
  const auth = useAuth();
  const arSavininkas = auth.user.role === 'Ūkis';

  const banduList = useGetQuery(arSavininkas ? 'bandos' : 'bandos/' + savId);

  const handleZymBanda = (target) => {
    changeBandos(target.checked, target.value);
    if (target.checked) {
      setZymVisos(bandos.length === banduList.length);
    } else {
      setZymVisos(false);
    }
  };

  const handleZymVisas = (checked) => {
    if (checked) {
      setZymVisos(true);
      handleZymVisosBandos(banduList.map((b) => Number(b.banda)));
    } else {
      setZymVisos(false);
      handleZymVisosBandos([]);
    }
  };

  if (isLoading) {
    const skeletonMap = [1, 2];

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '100%',
          }}
        >
          <Skeleton width='50%' height={30} />
          {skeletonMap.map((s) => (
            <Box
              key={s}
              sx={{ display: 'flex', gap: '20px', paddingLeft: '30px' }}
            >
              <Skeleton width={30} height={30} />
              <Skeleton width='100%' />
            </Box>
          ))}
        </Box>
      </>
    );
  }

  if (banduList.length === 0) {
    return <Typography>Pasirinkite bandas</Typography>;
  }

  return (
    <>
      <Typography>Pasirinkite bandas</Typography>
      <List
        disablePadding
        sx={{
          maxHeight: 300,
          position: 'relative',
          overflow: 'auto',
          width: '100%',
          scrollbarWidth: 'thin',
        }}
      >
        <ListItem key='zym-visas' disablePadding>
          <ListItemButton
            role={undefined}
            onClick={(e) => handleZymVisas(e.target.checked)}
          >
            <ListItemIcon>
              <Checkbox
                checked={zymVisos}
                value='1'
                inputProps={{ 'aria-labelledby': 'zym-visas' }}
              />
            </ListItemIcon>
            <ListItemText id='zym-visas' primary='Žymėti visas' />
          </ListItemButton>
        </ListItem>
        {banduList.map((banda) => (
          <ListItem key={banda.banda} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={(e) => handleZymBanda(e.target)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={bandos.indexOf(banda.banda) > -1 || zymVisos}
                  value={banda.banda}
                  inputProps={{ 'aria-labelledby': banda.banda }}
                />
              </ListItemIcon>
              <ListItemText id={banda.banda} primary={banda.banda} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default Bandos;
