import { Container, Paper } from '@mui/material';
import React from 'react';
import Savininkai from '../home/Savininkai';

const AsistentoMeniu = ({ savininkas, changeSavininkas }) => {
  return (
    <Container maxWidth='lg' sx={{ mt: 4, mb: 0 }}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: 100,
        }}
      >
        <Savininkai savId={savininkas} changeSavininkas={changeSavininkas} />
      </Paper>
    </Container>
  );
};

export default AsistentoMeniu;
