import React, { useState } from 'react';
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { Spalvos } from '../../constants/Spalvos';
import { Rodikliai } from '../../constants/Rodikliai';
import { Box, Typography } from '@mui/material';
import RodiklioSelect from '../shared/RodiklioSelect';
import { StyledLabel, StyledTooltip } from '../shared/StyledComponents';
import { kontrolesPeriodai } from '../../constants/KontrolesPeriodai';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`Karvės Nr.: ${payload[1].payload.karNr}`}</StyledLabel>
        <StyledLabel>{`Kontr. data: ${payload[1].payload.kontrData}`}</StyledLabel>
        <StyledLabel>{`Mėnuo: ${payload[1].payload.menuo}`}</StyledLabel>
        <StyledLabel>{`${payload[0].name}: ${payload[0].value}`}</StyledLabel>
        <StyledLabel>{`${payload[1].name}: ${payload[1].value}`}</StyledLabel>
      </StyledTooltip>
    );
  }

  return null;
};

const ProdGrafikas = ({ isLoading, duomenys, menesiai }) => {
  const [rodiklis, setRodiklis] = useState(Rodikliai[0]);

  const duomenuArr = [];
  for (let i = 0; i < menesiai.length; i++) {
    duomenuArr[i] = {
      menuo: menesiai[i],
      data: duomenys.filter((d) => d.menuo === menesiai[i]),
    };
  }

  const paramSveikiSkaiciai = ['sls', 'urea', 'primilzis', 'bazinis'];

  const tickValue = 50;
  let maxLaktDiena = Math.max(...duomenys.map((d) => d.laktDiena));
  maxLaktDiena === -Infinity && (maxLaktDiena = 1000);

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        marginBottom={2}
        gap={1}
      >
        <Typography>Rodiklio</Typography>
        <RodiklioSelect
          rodiklis={rodiklis}
          rodikliuList={Rodikliai}
          changeRodiklis={setRodiklis}
        />
        <Typography>kitimas per laktaciją</Typography>
      </Box>

      {isLoading ? (
        <Box
          width='100%'
          height={500}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height={500}>
          <ScatterChart
            margin={{
              left: rodiklis.parametras === 'sls' ? 55 : 15,
              bottom: 16,
            }}
          >
            <CartesianGrid
              verticalValues={[
                ...Array(Math.ceil(maxLaktDiena / tickValue) + 1).keys(),
              ].map((i) => i * tickValue)}
            />
            <XAxis
              type='number'
              dataKey='laktDiena'
              name='Laktacijos diena'
              label={{
                value: 'Karvės laktacijos diena kontrolės datai',
                position: 'insideBottom',
                offset: -10,
              }}
            />
            <YAxis
              type='number'
              dataKey={rodiklis.parametras}
              name={rodiklis.pavadinimas}
              unit={rodiklis.vienetai}
              label={{
                value: rodiklis.pavadinimas,
                angle: -90,
                position: 'insideLeft',
                offset: rodiklis.parametras === 'sls' ? -50 : -5,
              }}
              domain={([dataMin, dataMax]) => {
                return [
                  paramSveikiSkaiciai.indexOf(rodiklis.parametras) > -1
                    ? 0
                    : (dataMin - (dataMax - dataMin) / 10).toFixed(2),
                  paramSveikiSkaiciai.indexOf(rodiklis.parametras) > -1
                    ? Math.round(dataMax + (dataMax - dataMin) / 10)
                    : (dataMax + (dataMax - dataMin) / 10).toFixed(2),
                ];
              }}
            />
            <ReferenceLine
              x={100}
              stroke='black'
              strokeDasharray='3 3'
              ifOverflow='extendDomain'
            />
            <ReferenceLine
              x={200}
              stroke='black'
              strokeDasharray='3 3'
              ifOverflow='extendDomain'
            />

            <ReferenceLine
              x={300}
              stroke='black'
              strokeDasharray='3 3'
              ifOverflow='extendDomain'
            />
            <ZAxis range={[80, 81]} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip />}
            />
            <Legend
              verticalAlign='top'
              wrapperStyle={{ paddingBottom: '20px' }}
            />
            {duomenuArr.map((menuo) => (
              <Scatter
                key={menuo.menuo}
                name={menuo.menuo}
                data={menuo.data}
                fill={Spalvos[kontrolesPeriodai.indexOf(menuo.menuo)]}
                opacity={0.6}
              />
            ))}
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ProdGrafikas;
