import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Primary } from '../../constants/Spalvos';

const SveikatStulpGrafikas = ({ isLoading, pavadinimas, duomenys, margin }) => {
  const data = duomenys.filter((d) => !isNaN(d.value));
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      {isLoading ? (
        <Box
          width='100%'
          height='100%'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            data={data}
            layout='vertical'
            margin={{ left: margin, right: 30 }}
          >
            <XAxis type='number' />
            <YAxis dataKey='label' type='category' />
            <Tooltip />
            <Bar
              dataKey='value'
              barSize={20}
              label={{ position: 'right', fill: 'black' }}
              fill={Primary}
              activeBar={<Rectangle fill={Primary} stroke='black' />}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default SveikatStulpGrafikas;
