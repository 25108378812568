import React from 'react';
import { Button, Paper, TableCell, TableRow } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { useMenu } from '../../context/MenuProvider';
import { useAuth } from '../../context/AuthProvider';
import { useGetQuery } from '../../hooks/useQuery';

const columns = [
  { width: 200, label: 'Karvės Nr.', dataKey: 'karve' },
  { width: 200, label: 'Laktacijos Nr.', dataKey: 'laktNr' },
  { width: 200, label: 'Laktacijos Pradžia', dataKey: 'laktPradzia' },
  { width: 200, label: 'Laktacijos Pabaiga', dataKey: 'laktPabaiga' },
];

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant='head'
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{ backgroundColor: 'background.paper' }}
          size='small'
        >
          {column.label}
        </TableCell>
      ))}
      <TableCell
        variant='head'
        style={{ width: 100 }}
        sx={{ backgroundColor: 'background.paper' }}
        size='small'
      ></TableCell>
    </TableRow>
  );
};

const UkioKarviuLentele = ({
  veisle,
  laktacijos,
  karve,
  changeKarve,
  changeAnimation,
}) => {
  const menu = useMenu();
  const auth = useAuth();
  const savininkas = menu.savininkas;
  const arSavininkas = auth.user.role === 'Ūkis';

  const karves = useGetQuery(
    arSavininkas
      ? 'laktacijos/savininkai/karves'
      : 'laktacijos/savininkai/karves/' + savininkas
  );

  //Jei yra pazymeta visos laktacijos, tai nieko nefiltruosim
  let atrinktosKarves =
    laktacijos.indexOf(0) > -1
      ? karves
      : karves.filter((k) => laktacijos.indexOf(k.laktNr) > -1);

  //Atfiltruojam pagal veisles
  if (veisle !== 0) {
    atrinktosKarves = atrinktosKarves.filter((k) => k.veisle === veisle);
  }

  const handleClick = (karve) => {
    changeKarve(karve);
    changeAnimation(false);
  };

  const rowContent = (_index, row) => {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            size='small'
            style={{
              backgroundColor:
                karve.karve === row.karve && karve.laktNr === row.laktNr
                  ? '#fbff81'
                  : 'white',
            }}
          >
            {row[column.dataKey]}
          </TableCell>
        ))}
        <TableCell
          size='small'
          style={{
            backgroundColor:
              karve.karve === row.karve && karve.laktNr === row.laktNr
                ? '#fbff81'
                : 'white',
          }}
        >
          <Button
            variant='text'
            sx={{ paddingTop: 0, paddingBottom: 0 }}
            onClick={() => handleClick(row)}
          >
            Pasirinkti
          </Button>
        </TableCell>
      </React.Fragment>
    );
  };

  return (
    <Paper sx={{ height: 300, width: '100%', maxWidth: 800 }}>
      <TableVirtuoso
        data={atrinktosKarves}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        style={{ scrollbarWidth: 'thin' }}
      />
    </Paper>
  );
};

export default UkioKarviuLentele;
