import React from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const links = [
  {
    text: 'Rodiklių kitimas per laktaciją',
    path: '/',
    icon: <ManageSearchIcon />,
  },
  {
    text: 'Lietuvos laktacijų kreivės',
    path: '/laktacijuKreives',
    icon: <StackedLineChartIcon />,
  },
  {
    text: 'Ūkio laktacijų kreivės',
    path: '/ukioLaktKreives',
    icon: <QueryStatsIcon />,
  },
  {
    text: 'Bandos mitybos vertinimas',
    path: '/mitybosVertinimas',
    icon: <BarChartIcon />,
  },
  {
    text: 'Bandos sveikatingumas SLS',
    path: '/sveikatingumas',
    icon: <DonutLargeIcon />,
  },
  {
    text: 'Bandos SLS rodiklių kitimas',
    path: '/bandosSlsKitimas',
    icon: <TrendingDownIcon />,
  },
];

const DrawerMenu = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const handleClick = (path) => {
    toggleDrawer(false);
    auth.changeScreen(path);
    navigate(path);
  };

  return (
    <div>
      <Drawer open={open} onClose={() => toggleDrawer(false)}>
        <Box sx={{ width: 300 }}>
          <List>
            {links.map((item) => (
              <ListItem key={item.text}>
                <ListItemButton
                  style={{
                    backgroundColor: auth.screen === item.path && '#eae9e9',
                  }}
                  onClick={() => handleClick(item.path)}
                >
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            <ListItem key={'Atsijungti'}>
              <ListItemButton onClick={auth.logout}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Atsijungti' />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
