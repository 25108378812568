import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import './Login.css';
import { useAuth } from '../context/AuthProvider';
import logo from '../Pt_logo.gif';

const Login = () => {
  const auth = useAuth();
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    auth.error && auth.removeError();
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.login(input, setIsLoading, setTimer);
  };

  return (
    <div className='login-container'>
      <div className='dialog-container'>
        <img src={logo} alt='logo' />
        <div>
          <Typography>Prašome prisijungti</Typography>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className='form-container'>
              <TextField
                id='username'
                label='Prisijungimo vardas'
                variant='outlined'
                name='username'
                fullWidth
                required
                onChange={handleInput}
                disabled={isLoading || timer > 0}
              />
              <TextField
                id='password'
                label='Slaptažodis'
                variant='outlined'
                type='password'
                name='password'
                fullWidth
                required
                onChange={handleInput}
                disabled={isLoading || timer > 0}
              />
              <Button
                variant='contained'
                fullWidth
                disabled={isLoading || timer > 0}
                type='submit'
              >
                Prisijungti
              </Button>
              {auth.error && (
                <Box sx={{ width: '100%', maxWidth: '250px' }}>
                  <Typography sx={{ color: 'red' }}>
                    {timer > 0
                      ? `Neteisingi prisijungimo duomenys. Iki sekančio ` +
                        `bandymo prisijungti palaukite ${timer} sekundžių`
                      : auth.error}
                  </Typography>
                </Box>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
