import { useEffect, useState } from 'react';
import { getItem, postItem } from '../Api/Api';
import { useAuth } from '../context/AuthProvider';

export const useGetQuery = (url) => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (url) {
      getItem(url)
        .then((data) => {
          data.data && setData(data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            auth.logout();
          } else if (err.response.status === 403) {
            auth.logout();
          }
        });
    }
    // eslint-disable-next-line
  }, [url]);

  return data;
};

export const usePostQuery = (url, body) => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (url) {
      postItem(url, body)
        .then((data) => {
          data.data && setData(data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            auth.logout();
          } else if (err.response.status === 403) {
            auth.logout();
          }
        });
    }
    // eslint-disable-next-line
  }, [url, body]);

  return data;
};
