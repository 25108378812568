import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { kontrolesPeriodai } from '../../constants/KontrolesPeriodai';
import { useMenu } from '../../context/MenuProvider';
import { useAuth } from '../../context/AuthProvider';

const MenesiuSelect = ({ menesiai, changeMenesiai }) => {
  const menu = useMenu();
  const auth = useAuth();
  const [zymVisos, setZymVisos] = useState(false);

  const savininkas = menu.savininkas;
  const arSavininkas = auth.user.role === 'Ūkis';

  const handleChange = (checked, value) => {
    if (checked) {
      changeMenesiai([...menesiai, value]);
      setZymVisos(menesiai.length + 1 === kontrolesPeriodai);
    } else {
      changeMenesiai(menesiai.filter((item) => item !== value));
      setZymVisos(false);
    }
  };

  const handleZymVisas = (checked) => {
    if (checked) {
      setZymVisos(true);
      changeMenesiai(kontrolesPeriodai);
    } else {
      setZymVisos(false);
      changeMenesiai([]);
    }
  };

  return (
    <>
      <Typography>Kontrolės mėn.</Typography>
      <Select
        id='menesioSelect'
        value={menesiai}
        multiple
        fullWidth
        disabled={!arSavininkas && savininkas === ''}
        renderValue={() =>
          zymVisos ? 'Visi' : 'Pasirinkta: ' + menesiai.length.toString()
        }
        size='small'
        sx={{ maxWidth: '100%' }}
        title={
          !arSavininkas && savininkas === '' ? 'Pasirinkite savininką' : ''
        }
      >
        <MenuItem key={0} value=''>
          <Checkbox
            checked={zymVisos}
            value=''
            onChange={(e) => handleZymVisas(e.target.checked)}
          />
          <ListItemText primary='Visi' />
        </MenuItem>
        {kontrolesPeriodai.map((m) => (
          <MenuItem key={m} value={m}>
            <Checkbox
              value={m}
              checked={menesiai.indexOf(m) > -1 || zymVisos}
              onChange={(e) => handleChange(e.target.checked, e.target.value)}
            />
            <ListItemText primary={m} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default MenesiuSelect;
