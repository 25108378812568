import React from 'react';
import { Container, Grid, Paper } from '@mui/material';
import Rajonai from '../home/Rajonai';
import Savininkai from '../home/Savininkai';

const VadovoMeniu = ({
  rajonas,
  savininkas,
  changeRajonas,
  changeSavininkas,
}) => {
  return (
    <Container maxWidth='lg' sx={{ mt: 4, mb: 0 }}>
      <Grid container justifyContent='center' spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              height: 100,
            }}
          >
            <Rajonai rajId={rajonas} changeRajonas={changeRajonas} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={8}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              height: 100,
            }}
          >
            <Savininkai
              rajId={rajonas}
              savId={savininkas}
              changeSavininkas={changeSavininkas}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VadovoMeniu;
