import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItem, postItem } from '../Api/Api';
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: '',
    role: '',
  });
  const [error, setError] = useState(null);
  const [screen, setScreen] = useState(
    JSON.parse(localStorage.getItem('puslapis')) || '/'
  );
  const navigate = useNavigate();

  useEffect(() => {
    user.name === '' &&
      getItem('user')
        .then((data) => {
          const userData = data.data && {
            name: data.data.vardas,
            role: data.data.pareigos,
          };
          setUser(userData);
          navigate(screen);
        })
        .catch(() => {
          navigate('/login');
        });
    // eslint-disable-next-line
  }, []);

  const login = (data, setLoading, setTimer) => {
    setLoading(true);
    postItem('login', data)
      .then((data) => {
        const userData = data.data && {
          name: data.data.vardas,
          role: data.data.pareigos,
        };
        setUser(userData);
        setError(null);
        navigate(screen);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.klaida);
        setLoading(false);
        setTimer(err.response.data.stabdymas || 0);
      });
  };

  const logout = () => {
    postItem('logout', null)
      .then(() => {
        setUser({ name: '', role: '' });
        navigate('/login');
      })
      .catch((err) => {
        err.response && console.log(err.response.data);
      });
  };

  const removeError = () => {
    setError(null);
  };

  const changeScreen = (value) => {
    setScreen(value);
    localStorage.setItem('puslapis', JSON.stringify(value));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        screen,
        login,
        logout,
        removeError,
        changeScreen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
