import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { useGetQuery } from '../../hooks/useQuery';

const Savininkai = ({ rajId, savId, changeSavininkas }) => {
  const auth = useAuth();
  const arVadovas = auth.user.role === 'Vadovas';

  const savininkai = useGetQuery(
    arVadovas ? 'savininkai/' + rajId : 'savininkai'
  );

  return (
    <>
      <Typography>
        {savId ? 'Savininkas:' : 'Pasirinkite savininką:'}
      </Typography>
      <Select
        id='savininkoSelect'
        value={savId}
        label=''
        fullWidth
        onChange={(e) => changeSavininkas(e.target.value)}
        sx={{ textAlign: 'left' }}
        size='small'
      >
        <MenuItem key='empty' disabled value=''>
          <em>Pasirinkti</em>
        </MenuItem>
        {savininkai.map((s) => (
          <MenuItem key={s.gptKodas} value={s.gptKodas}>
            {s.gptKodas + '.' + s.savininkas}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Savininkai;
