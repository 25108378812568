export const periodai = [];

for (let i = 0; i <= 11; i++) {
  let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let date = new Date(firstDay.setMonth(firstDay.getMonth() - i));
  //liepos menesi metam lauk
  if (date.getMonth() !== 6) {
    periodai.push(
      `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}`
    );
  }
}

export const kontrolesPeriodai = periodai;