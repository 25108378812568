import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const KoeficientoPranesimas = ({ isOpen, handleClose }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Laktacijos kreivės tolygumo koeficientas</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText sx={{ color: 'black' }}>
          Laktacijos kreivės tolygumo koeficientas (%)
          <br /> P=(P2:P1)x100
          <br />P - per antrą ir per pirmą 100 laktacijos dienų primelžto pieno
          (P2 ir P1) kiekio santykis procentais. <br />
          <br />
          Karvių, kurių laktacijos kreivė aukšta, tolygi P būna 97-99 %, o kurių
          žema, netolygi- (krintanti) -75-78%. Karvės, kurių laktacijos kreivė
          aukšta ir tolygi, yra stiprios konstitucijos, labai produktyvios,
          gerai įsisąvina pašarus, yra vislios. Laktacijos pradžioje iš jų
          primelžiama daug pieno, o vėliau jų pieningumas ne daugiau kaip 6% per
          mėnesį mažėja. Iš tokių karvių primelžiama daugiau pieno ne tik per
          laktaciją, bet ir per visą jų gyvenimą. Karvės , kurių kreivė žema ir
          netolygi, yra silpnos konstitucijos, jų organizmas neprisitaikęs
          didesniam krūviui, jos būna neproduktyvios. Jų pieningumas po
          didžiausio primilžio kas mėnesį mažėja 9-12%.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default KoeficientoPranesimas;
