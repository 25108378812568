import axios from 'axios';

export const baseUrl = 'https://xi.pieno-tyrimai.lt:12002/apex/webservices/karvute/v1';

export const getItem = async (handler) => {
  const response = await axios.get(baseUrl + '/' + handler, {withCredentials: true});
  return response;
};

export const postItem = async (handler, data) => {
  const response = await axios.post(baseUrl + '/' + handler, data, {withCredentials: true});
  return response;
};